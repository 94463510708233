import { toUnicode } from 'punycode';

export const EMPTY_VALUE_PLACEHOLDER = '\u2014'; // em dash

export const formatDomainName = (domain?: string) => {
  if (!domain) {
    return EMPTY_VALUE_PLACEHOLDER;
  } else if (domain.startsWith('xn--')) {
    return `${domain} (${toUnicode(domain)})`;
  }
  return domain;
};
